import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p marginwidth="70px">
          <h2> Resatisfy.Us</h2>


          Welcome to Resatisfy.Us. 
          One day, this site will be home to a fully fledged toolset and community that enables the growth of need literacy. Motivation and project background can be found <a href="https://www.notion.so/Networks-for-Understanding-Human-Need-73058f5a7f2c44b781f817ee8e061c24">on this virtual poster.  </a><br/><br/>
          For now, you can experience the raw prototypes of the systematic needs assessment toolchain. <br/>
          
          You can download a <a href='https://github.com/mjhoefer/need_nets_v2'> Python script + CSV file </a> 
          that enables you to take a need-net survey (Python command line)  based on a need schema defined in the CSV. 
          
          You can then visit a <a href='https://www.michaelhoefer.com/files/index.html'>visualization tool</a> to see how the need net looks (select "choose file" to view your network).
          The visualization all happens locally in HTML, javascript, and CSS. 
          If you'd like feel free to submit your network using <a href="https://docs.google.com/forms/d/e/1FAIpQLScnc4egT9dE_ryvuvudlcHQug3MC_BT4wRdjYbhlW68JVYSuA/viewform?usp=sf_link"> this google form</a>, and it will be aggregated with the other participants to create an IC2S2 participants need network. 
          <br/><br/>Get in touch with me at <a href="mailto:michael.hoefer@colorado.edu">michael.hoefer@colorado.edu</a>, or enter your email below and I'll keep you updated on the project.

          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdpnQGsmFG6uDKuWt7kkFBPrpklyBHybMNa3fo7E_43PeJ5cA/viewform?embedded=true" width="640" height="448" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </p>
      </header>
    </div>
  );
}

export default App;
